import React from "react";
import Head from "../../../components/head";
// import Side from "../../../components/sidebar";
import Nav from "../../../components/navMenu";
import { Redirect, useLocation } from 'react-router';
import routeAll from '../../../helpers/route';


const routeAllUser = {...routeAll.routesUser}
function Main(props){
    const location = useLocation()
    if(Object.values(routeAllUser).findIndex(list => list.path === location.pathname) < 0){
        
        return(
            <Redirect to={routeAll.routesComponent.notFound.path} exact/>
        )
    }
    else{
        if(Object.values(routeAllUser).find(list => list.path === location.pathname)){
            return (
                    <>
                        <Nav/>
                        <div className="container-fluid bg-light" style={{padding: '0 5%'}}>
                            <Head.Header/>
                            <div className="row" style={{padding: '2% 0'}}>
                                
                                {/* <div className="col-md-2 bg-body custom-grid" >
                                    <style>
                                        {`
                                        .list-group {
                                            padding-left: 0;
                                            margin-bottom: 0px;                
                                        }
                                        `}
                                    </style>
                                    <Side/>
                                </div> */}
                                
                                {/* <div className='col-sm-12 col-md-9 offset-md-1 bg-body custom-grid'> */}
                                <div className="bg-body custom-grid">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </>
            );
        }
    }
}
export default Main;