import React from 'react';
import { runWithAdal } from 'react-adal';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { LoadingPage } from './components/Loading';
import { adalConfig, authContext } from './helpers/adalConfig';
import { securityData } from './helpers/variables';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';

const rootElement = document.getElementById('root');
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const DO_NOT_LOGIN = false;
// const graphRoute = env.graphRoute || 'https://graph.microsoft.com'

authContext.handleWindowCallback()
const root = createRoot(rootElement);

console.log(process.env.NODE_ENV)

if (process.env.NODE_ENV !== "development")
    console.log = () => {};

if ((window === window.parent) && window === window.top && !authContext.isCallback(window.location.hash)) {
    // console.log("1")
    // console.log(authContext.getCachedToken(adalConfig.clientId))
    if(!authContext.getCachedToken(adalConfig.clientId) || !authContext.getCachedUser()){
        // console.log("2")
        localStorage.clear()
        authContext.login()
    }else{
    //   console.log("3")
      root.render(
            <LoadingPage loading={true}/>
        )
    //   authContext.acquireToken(graphRoute, (message, access_token) => {
    //     console.log(message)
        const access_token = authContext.getCachedToken(adalConfig.clientId)
        if(authContext.getCachedToken(adalConfig.clientId)){
            // console.log(access_token)
            runWithAdal(authContext,()=>{

                securityData.Security_IsLogin(access_token).then(()=>{
                    // console.log("4")
                    root.render(
                        <LoadingPage loading={true}/>
                    )
                }).finally(()=>{
                    // console.log("5")
                    root.render(
                        <BrowserRouter basename ={baseUrl}>
                            <DndProvider backend={HTML5Backend}>
                                <App cachedUser={authContext.getCachedUser()}/>
                            </DndProvider>
                        </BrowserRouter>
                    )
                })
                
              }, DO_NOT_LOGIN)
        }else{
            root.render(
                <LoadingPage loading={true}/>
            )
        }
    // })
      
    }
    localStorage.setItem("userName", authContext.getCachedUser().userName)
    
}






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

