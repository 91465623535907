import React, { 
  Suspense, useState
} from 'react';
import { Switch, Route } from 'react-router-dom';
import routeAll from './helpers/route';
import { LoadingPage } from './components/Loading';
import Layout from './views/users/master/main';

function App(props) {
    const [loading, setLoading] = useState(true)

    const showLoading = (val)=>{
          setLoading(val)
      }

    const renderRoute = (value, idx)=>{
        return(
          <Route 
            key={idx} 
            name={value.name} 
            path={value.path} 
            exact={value.exact}
            
            render={(props) => (
                <value.component {...props} pageName={value.pageName} name={value.name} loading={showLoading} setLoading={setLoading}/>
          )} />
        ) 
    }

    return (
        <Switch>
            <LoadingPage loading={loading}>
            {
                Object.values(routeAll).map((value, idxParentRoute,array)=>
                    <Route path={
                        idxParentRoute===0 ?'/error/:path?' :
                        idxParentRoute===1?'/:path?' :
                        null
                    } 
                    key={idxParentRoute} exact>
                        <Suspense fallback={
                            <LoadingPage loading={true}/>
                        }>
                            {
                                idxParentRoute===0? 
                                Object.values(array[idxParentRoute]).map((value,idxChildren)=>
                                    renderRoute(value,idxChildren)
                                ) 
                                :
                                idxParentRoute===1?
                                    <Layout homePath={routeAll.routesUser.home.path} loading={showLoading} setLoading={setLoading}>
                                        {Object.values(array[idxParentRoute]).map((value,idxChildren)=>
                                            renderRoute(value,idxChildren)
                                        )}
                                    </Layout>
                                    : null
                            }
                        </Suspense>
                    </Route>
                )
            }
            </LoadingPage>
        </Switch>
    );
}

export default App;
