import axiosLibrary from "./axiosLibrary";
import { authContext } from './adalConfig';

authContext.handleWindowCallback()

const env = {
    // apiBaseUri : process.env.NODE_ENV ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL_QA,
    assets : process.env.REACT_APP_ASSETS,
    userDocument : process.env.REACT_APP_USER_DOCUMENT,
    expSession : process.env.REACT_APP_EXP_SESSION,
    graphRoute : process.env.REACT_APP_GRAPH_ROUTE,
    azureWindowLog : process.env.REACT_APP_AZURE_WINDOW_LOG,
    nodeEnv: process.env.NODE_ENV,

    //AWS
    awsAccessId : process.env.REACT_APP_S3_ACCESS_ID,
    awsAccessKey : process.env.REACT_APP_S3_ACCESS_KEY,

    awsRegionDev: process.env.REACT_APP_S3_REGION_DEV,
    awsRegionQA: process.env.REACT_APP_S3_REGION_QA,
    awsRegionProd: process.env.REACT_APP_S3_REGION_PROD,

    awsBucketLogDev: process.env.REACT_APP_S3_BUCKET_DEV,
    awsBucketLogQA: process.env.REACT_APP_S3_BUCKET_QA,
    awsBucketLogProd: process.env.REACT_APP_S3_BUCKET_PRD,
    
    awsRPAFolder: process.env.REACT_APP_S3_FOLDER,
    awsLambdaAPI: process.env.REACT_APP_API_LAMBDA,

    
    // awsRegion: process.env.NODE_ENV === 'development' ?  process.env.REACT_APP_S3_REGION_DEV : process.env.REACT_APP_S3_REGION_QA,
    // awsBucketLog: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_S3_BUCKET_DEV : process.env.REACT_APP_S3_BUCKET_QA,
    apiBaseUri: process.env.REACT_APP_API_BASE_URL,
    awsRegion: process.env.REACT_APP_S3_REGION,
    awsBucketLog: process.env.REACT_APP_S3_BUCKET
}

class securityData{

    static async Security_Azure(){
        authContext.acquireToken(env.graphRoute, (message, access_token) => {
            // console.log(message, access_token)
            if(access_token){
                localStorage.setItem(access_token, "token")
                axiosLibrary.login(access_token);
            }
        })
    }

    static async Security_IsLogin(access_token){
        const token = access_token;
        localStorage.setItem("token_from_backend", token)
        // console.log(token)
        if(token!==null){
            return true
        }else{ 
            await this.Security_Azure();
            return false
        }
    }
}

const userData = {
    email: localStorage.getItem('userName')
}

const vars = {
    loading: false,
    loadButton: false,
    deleteData: false,
    cancelDelete: false,
    responseData: null
}


export {env, securityData, userData, vars};
