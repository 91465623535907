// import Home from "../views/user/home";
import React from 'react';
// route user
const Home = React.lazy(() => import('../views/users/home') )
const Machine = React.lazy(() => import('../views/users/Machine') )
const MachineDetail = React.lazy(() => import('../views/users/MachineDetail') )
const Process = React.lazy(() => import('../views/users/Process') )
const ProcessDetail = React.lazy(() => import('../views/users/ProcessDetail') )
const Session = React.lazy(() => import('../views/users/Report/SessionList') )
// route comp
const AccessDenied = React.lazy(() => import('../components/accessDenied') )
const NotFound = React.lazy(() => import('../components/notFound') )

const routesUser = {
    home:{path: '/home', name: 'Home', component: Home, exact: true, pageName:"Home"},
    machine:{path: '/machine', name: 'Machine', component: Machine, exact: true, pageName:"Automation Machine"},
    machineDetail:{path: '/machineDetail', name: 'Machine Detail', component: MachineDetail, exact: true, pageName:"Automation Machine"},
    process:{path: '/process', name: 'Process', component: Process, exact: true, pageName:"Automation Process"},
    processDetail:{path: '/processDetail', name: 'Sub Process', component: ProcessDetail, exact: true, pageName:"Automation Process"},
    session:{path: '/', name: 'Session', component: Session, exact: true, pageName:"Session List"},

}

const routesComponent = {
    notFound:{path: '/error/404', name: '404 Not Found', component: NotFound, exact: true, pageName:"404 Not Found"},
    accessDenied:{path: '/error/400', name: 'ACCESS DENIED', component: AccessDenied, exact: true, pageName:"ACCESS DENIED"},

}

const root = {path:'/'}

const routeAll = { 
    routesComponent,
    routesUser,
    root,
}

export default routeAll;