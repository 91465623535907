/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export function loadAnim(param){
    if (param === 'page'){
        return(
            <div className="d-flex align-items-center">
                <strong>Loading...</strong>
                <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
            </div>
        )
    }
    if (param === 'button'){
        return(
            <>
                Loading...
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </>
        )
    }
} 

export function cssTarget(param){
    let opacity = 0
    let visibility='hidden'
    if(!param){
        opacity = 1
        visibility='visible'
    }
    return {
        visibility:visibility,opacity:opacity,transition:"linear",transitionDuration:"0.5s"
    }
}

export function cssSource(param){
    let opacity = 1
    if(!param){
        opacity = 0
    }
    return {
        opacity:opacity,transition:"linear",transitionDuration:"0.5s",position:'absolute',top:"50%",left:"45%",border:'unset'
    }
}

export function cssSourceData(param,type=null){
    let opacity = 1

    if(!param){
        opacity = 0
    }
    return {
        opacity:opacity,transition:"linear",transitionDuration:"0.5s",border:'unset',position:'absolute',top:type==='popup'?'25%':'5%',left:type==='popup'?'37%':'43%'
    }
}


export function cssSourcePage(param){
    let opacity = 1
    let visibility='visible'
    if(!param){
        opacity = 0
        visibility='hidden'
    }
    if(isMobile){
        return {
            visibility:visibility,opacity:opacity,transition:"linear",transitionDuration:"0.5s",top:"45%",left:"35%", position:'absolute'
        }
    
    }else{
        return {
            visibility:visibility,opacity:opacity,transition:"linear",transitionDuration:"0.5s",top:"35%",left:"45%", position:'absolute'
        }
    
    }
}

export function cssSourceDataButton(param,type=null){
    let opacity = 1

    if(!param){
        opacity = 0
    }
    return {
        opacity:opacity,transition:"linear",transitionDuration:"0.5s",border:'unset',position:'absolute',top:type==='popup'?'40%':'5%',left:'34%',width:'32%', filter:'invert(100%) sepia(0%) saturate(0%) hue-rotate(195deg) brightness(102%) contrast(102%)'
    }
}

export function LoadingData(props){
    const loading = props.loading
    return (
        <div style={cssSourceData(loading,props.type)}>
            {loadAnim('data')}
        </div>
    )
}


export function LoadingPage(props){
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        let timer = setTimeout(()=>setLoading(props.loading),1000)
        return () => {
            clearTimeout(timer)
        }
    },[props.loading])

    return(
        <div>
            <div style={cssSourcePage(loading)}>
                {loadAnim('page')}
            </div>
            <div style={cssTarget(loading)}>
                {props.children}
            </div>

        </div>
    )
}

export function LoadingDataButton(){
    return (
        loadAnim('button')
    )
}