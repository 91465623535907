/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import routeAll from '../helpers/route';
// import axiosLibrary from '../helpers/axiosLibrary';
import { env } from '../helpers/variables';
// import { authContext } from '../helpers/adalConfig';


function navBar(props){

    // const handleLogout =()=>{
    //     // axiosLibrary.logOut()
    //     localStorage.clear();
    //     authContext.logOut()
    // }
    

    return(
    <header id="header">
        <style>{
            `.lang-menu{
              /* margin: 20px -70px 0 20px;
                display:block;
                position:relative;
                float:right;
                margin:-45px 70px 0 0;*/
              }
              .lang-menu a{
                color: #000;
                
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 14px;
                line-height: 1.42857143;
              }
              .lang-menu a.active{
                font-weight: bold;
              }
            
              .lang-mobile{
                position: absolute;
                right: 80px;
                top: -7px;
                color: #000;
              }

              #lihome a{
                color:#6252bd;
              }

              .container {
                width: 1200px;
                }
            
              div#layout-content {
                  padding: 5px 0 0px;
                  background: #fff;
              }
              #platform-button{
                background-color: transparent;
                color: #5f6ec3 !important;
                border-color: #5f6ec3;
                border: 2px solid;
                font-weight: bold;
                /*border-radius: 20px;*/
              }
            `
            }
        </style>

        <nav class="navbar">
            <div class="container-fluid" style={{placeContent:'center'}}>
                <a className="navbar-brand text-primary" id="logo" href={routeAll.root.path} style={{textAlign: 'center'}}>
                    <img src={env.assets+"img/pmi.png"} alt="Control Room" style={{width:"10%"}} />
                    &nbsp;
                    Automation Next Gen Control Room
                </a>
            </div>
        </nav>
      {/* <nav className="navbar navbar-expand-lg navbar-fixed-top navbar-default menu ">
        <div className="container">

          <div className="navbar-header">

          
            <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
              <span className="">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            
            <a className="navbar-brand" id="logo" href={routeAll.root.path}>
              <img src={env.assets+"img/robot-favicon.png"} alt="Control Room" style={{width:"10%"}} />
              Automation Next Gen Control Room
            </a>
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav ml-auto main-menu">

                <li className="dropdown" id="lihome" style={{paddingTop:"5px",cursor:'pointer'}}>
                <a onClick={handleLogout.bind(this)} tabIndex="0" role="button">
                  <i className="fa-solid fa-circle-left"></i>
                  &nbsp;&nbsp;logout
                </a>   
              </li>
            </ul>

          </div> 
        </div>
      </nav> */}
    </header>

    )
}

export default navBar;