import axios from 'axios';
import routeAll from './route';
import { env} from './variables';
import {authContext} from './adalConfig';

class axiosLibrary{

    encodeID= async (param)=>{
        let responseJson = await this.postData('GetMd5',{id:param});
        return responseJson.data.data; 
    }

    getAuthHeader() {
        // console.log(localStorage.getItem("token_from_backend"))
        return {headers: {
                     Authorization: 'Bearer ' + localStorage.getItem("token_from_backend"),
                     "Access-Control-Allow-Origin": "*"
                 }/*,withCredentials: true,*/
             };
     }

    async loginFirst(credentials){
        try {
            const response = await axios.post(env.apiBaseUri + "login", credentials);
            return response
        } catch (error) {
            return error.response
        }
       
    }

    async login(access_token){
            console.log(access_token)
        if(!localStorage.getItem("userinfo")){
            // console.log(access_token)
            let responseLogin = await this.loginFirst({access_token:access_token});
            console.log(responseLogin)
            switch (responseLogin.status) {
                case 200:
                    localStorage.setItem("token_from_backend", responseLogin.data.token)
                    console.log(responseLogin)
                    var lang = {
                        lang:'ENG'
                    }
                    var response = {...responseLogin.data.data, ...lang};
                    if(!localStorage.getItem("userinfo")){
                        localStorage.setItem("userinfo", JSON.stringify(response));
                    }
                    break;
                case 400:
                    alert("SORRY, YOU DONT HAVE PERMISSION TO SEE THIS PAGE");
                    window.location.href = routeAll.routesComponent.accessDenied.path
                    break;
                case 500:
                    alert("API NO RESPONSE, CLICK OK TO RELOAD");
                    localStorage.clear();
                    authContext.login();
                    break;
            default:
                    localStorage.clear();
                    break;
            }
        }
    }

    async logOut() {
        try {
            const response = await axios.post(env.apiBaseUri + 'logout', {}, this.getAuthHeader());
            localStorage.clear();
            authContext.logOut();
            return response
        } catch (error) {
            return error.response
        }
        
    }

    getAzureToken(){
        return JSON.parse(localStorage.getItem("token"));
    }

    async postData(suburl,param){
        try {
            const config = this.getAuthHeader();
            // console.log(config)
            const response = await axios.post(env.apiBaseUri + suburl, param, config);
            return response
        } catch (error) {
            if (error.code === "ERR_NETWORK"){
                console.log(error.message)
            }
            else if(error.response.status !== undefined){
                switch (error.response.status) {
                    case 401:
                        let responseApiRefresh = await this.postDataRefresh("awbrefreshToken");
                        if(responseApiRefresh.status===200){
                            // console.log(responseApiRefresh)
                            localStorage.setItem("token_from_backend", responseApiRefresh.data.newToken);
                            return await this.postData(suburl,param);
                        }else{
                            return error.response
                        }
                    case 400:
                        alert("SESSION EXPIRED, CLICK OK TO RELOGIN");
                        const response = await this.logOut();
                        console.log(response)
                        if(response.status===200){
                            authContext.login();
                            // securityData.Security_Azure();
                        }
                        return error.response
                    case 403:
                        alert("YOU DONT HAVE PERMISSION TO SEE THIS PAGE, CLICK OK TO REDIRECT");
                        window.location.href = routeAll.routesComponent.accessDenied.path
                        return error.response
                    case 500:
                        alert("API NO RESPONSE, CLICK OK TO RELOAD");
                        const getUrl = window.location.href;
                        if(getUrl.includes("dev")||getUrl.includes("qas")||getUrl.includes("localhost")){
                            // console.log("error api")
                        }else{
                            window.location.href = getUrl;
                        }
                        return error.response
                    default:
                        return error.response
                }
            }
        }
    }

    async getData(suburl,config){
        try {
            const url = env.apiBaseUri + suburl
            const config = this.getAuthHeader();
            const response = await axios.get(url, config);
            return response;
        } catch (error) {
            // handle error
            switch (error.response.status) {
                case 401:
                    let responseApiRefresh = await this.postDataRefresh("awbrefreshToken");
                    if(responseApiRefresh.status===200){
                        localStorage.setItem("token_from_backend", responseApiRefresh.data.newToken);
                        return await this.getData(suburl,config);
                    }else{
                        return error.response
                    }
                case 400:
                    alert("SESSION EXPIRED, CLICK OK TO RELOGIN");
                    const response = await this.logOut();
                    if(response.status===200){
                    }
                    return error.response
                case 403:
                    alert("YOU DONT HAVE PERMISSION TO SEE THIS PAGE, CLICK OK TO REDIRECT");
                    window.location.href = routeAll.routesComponent.accessDenied.path
                    return error.response
                case 500:
                    alert("API NO RESPONSE, CLICK OK TO RELOAD");
                    const getUrl = window.location.href;
                    if(getUrl.includes("dev")||getUrl.includes("qas")||getUrl.includes("localhost")){
                        // console.log("error api")
                    }else{
                        window.location.href = getUrl;
                    }
                    return error.response
                default:
                    return error.response
            }
        }
    }

    async postDataFile(suburl,param){
        try {
            const config = { 
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem("token_from_backend") ,
                },
                responseType: 'blob'
            };            
            const response = await axios.post(env.apiBaseUri + suburl, param, config);
            return response;
        } catch (error) {
            switch (error.response.status) {
                case 401:
                    let responseApiRefresh = await this.postDataRefresh("awbrefreshToken");
                    if(responseApiRefresh.status===200){
                        localStorage.setItem("token_from_backend", responseApiRefresh.data.newToken);
                        return await this.postDataFile(suburl,param);
                    }else{
                        return error.response
                    }
                case 400:
                    alert("SESSION EXPIRED, CLICK OK TO RELOGIN");
                    const response = await this.logOut();
                    if(response.status===200){
                        //authContext\.login\(\);
                    }
                    return error.response
                case 403:
                    alert("YOU DONT HAVE PERMISSION TO SEE THIS PAGE, CLICK OK TO REDIRECT");
                    window.location.href = routeAll.routesComponent.accessDenied.path
                    return error.response
                case 500:
                    alert("API NO RESPONSE, CLICK OK TO RELOAD");
                    const getUrl = window.location.href;
                    if(getUrl.includes("dev")||getUrl.includes("qas")||getUrl.includes("localhost")){
                        // console.log("error api")
                    }else{
                        window.location.href = getUrl;
                    }
                    return error.response
                default:
                    return error.response
            }
        }
    }

}

export default new axiosLibrary();